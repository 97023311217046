<template>
  <div>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Acessos</span>
        </template>
        <LogAcessos />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Operações</span>
        </template>
        <LogOperacoes />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import LogAcessos from './LogAcessos.vue'
  import LogOperacoes from './LogOperacoes.vue'
  export default {
    components: {
      LogAcessos,
      LogOperacoes,
    },
    data() {
      return {}
    },
  }
</script>
